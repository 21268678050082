/* eslint-disable jsx-a11y/anchor-is-valid */
import { FileAddOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Select,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader/AppHeader";
import postAuth from "../functions/postAuth";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import getAuth from "../functions/getAuth";
import { saveAs } from "file-saver";
import CrossCheckTable from "../components/Hyderabad/CrossCheckTable";

const ExcelUploadModal = ({
  isOpen,
  setIsOpen,
  requestId,
  onComplete = () => {},
}) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setFileList([]);
  }, [isOpen]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleUpload = () => {
    // Implement file upload handling logic here
    console.log(fileList[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileList[0]);

    fileReader.onload = (e) => {
      const base64Str = fileReader.result;
      const file_base64 = base64Str.split("base64,").pop();
      postAuth("/api/hyd_pharma_cross_check", {
        request_id: requestId,
        file_base64,
      })
        .then(async (r) => {
          setIsUploading(false);
          setIsOpen(false);
          const rj = await r.json();
          onComplete(rj);
        })
        .catch(() => alert("ERROR"))
        .finally(() => {
          setIsUploading(false);
          setIsOpen(false);
        });
    };
    setIsUploading(true);
  };

  const handleBeforeUpload = (file) => {
    const isExcel =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    if (!isExcel) {
      message.error("You can only upload Excel files!");
      return Upload.LIST_IGNORE;
    }
    setFileList([file]);
    return false;
  };

  return (
    <>
      <Modal
        open={isOpen}
        title="Upload Excel File"
        onCancel={handleCancel}
        footer={
          isUploading
            ? []
            : [
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="upload"
                  type="primary"
                  onClick={handleUpload}
                  disabled={!fileList.length}
                >
                  Upload
                </Button>,
              ]
        }
      >
        {isUploading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" style={{ margin: 10 }} />
            <Typography.Text>
              Processing... Do not refresh or close this window.
            </Typography.Text>
          </div>
        ) : (
          <>
            <Upload
              beforeUpload={handleBeforeUpload}
              fileList={fileList}
              onRemove={() => setFileList([])}
              accept=".xlsx"
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                marginTop: 10,
              }}
            >
              <Typography.Text>Select Project</Typography.Text>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

const HyderabadPharmaCrossCheck = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const authCheck = useAuth();
  const { rxid } = useParams();

  useEffect(() => {
    if (!authCheck()) {
      navigate("/login");
    }
  }, [authCheck, navigate]);

  return (
    <>
      <ExcelUploadModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        requestId={rxid}
        onComplete={(res) => {
          setData(res);
        }}
      />
      <Layout style={{ minHeight: "100vh" }}>
        <AppHeader />
        <Layout.Content style={{ padding: "0 48px", minHeight: "100vh" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <a href="/" onClick={() => window.location.assign("/")}>
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd">Hyderabad Team</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd/hyd-pharma">Hyderabad Pharma</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="#" style={{ color: "black" }}>
                Upload
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
          {data ? (
            <CrossCheckTable data={data} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <Typography.Text style={{ fontWeight: "bold", marginBottom: 20 }}>
                Request ID : {rxid}
              </Typography.Text>
              <Typography.Text style={{ marginBottom: 20 }}>
                To cross-check, please upload a manual checked sheet that
                corresponds to{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    getAuth(`/api/hyd_pharma_get_file?file=/tmp/${rxid}.xlsx`)
                      .then(async (r) => {
                        saveAs(await r.blob(), `cross-check-${rxid}.xlsx`);
                      })
                      .catch((e) => alert(e.toString()));
                  }}
                >
                  this tool generated sheet
                </a>
              </Typography.Text>
              <Typography.Text style={{ marginBottom: 20 }}>
                Press the button to upload a manual tagged sheet for
                cross-checking
              </Typography.Text>
              <Button
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  padding: 20,
                  height: 150,
                  width: 250,
                  margin: 10,
                }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <FileAddOutlined style={{ fontSize: 35, marginBottom: 10 }} />
                <Typography.Text>Upload</Typography.Text>
              </Button>
            </div>
          )}
        </Layout.Content>
      </Layout>
    </>
  );
};

export default HyderabadPharmaCrossCheck;
