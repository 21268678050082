import React from "react";
import { Tabs, Table, Typography } from "antd";

const { TabPane } = Tabs;
const { Title } = Typography;

const CrossCheckTable = ({ data }) => {
  const tabs = Object.keys(data);

  return (
    <Tabs defaultActiveKey={tabs[0]}>
      {tabs.map((upperKey) => {
        const rows = data[upperKey];
        const conflictRows = [];
        let totalConflicts = 0;

        // Iterate over each row in the current tab
        Object.values(rows).forEach((row, rowIndex) => {
          // For each row, iterate through the fields
          Object.entries(row).forEach(([field, values]) => {
            const { auto, manual } = values;
            const conflict = auto !== manual; // Check for conflict

            if (conflict) {
              totalConflicts++;
            }
            if (conflict)
              conflictRows.push({
                field,
                auto,
                manual,
                conflict: conflict ? "Conflict" : "No Conflict",
                rowNumber: rowIndex + 1, // Increment by 1 for human-readable index
              });
          });
        });

        const accuracy =
          conflictRows.length > 0
            ? ((conflictRows.length - totalConflicts) / conflictRows.length) *
              100
            : 0;

        const columns = [
          {
            title: "Row Number",
            dataIndex: "rowNumber",
            key: "rowNumber",
          },
          {
            title: "Field",
            dataIndex: "field",
            key: "field",
          },
          {
            title: "Auto Value",
            dataIndex: "auto",
            key: "auto",
          },
          {
            title: "Manual Value",
            dataIndex: "manual",
            key: "manual",
          },
          {
            title: "Conflict Status",
            dataIndex: "conflict",
            key: "conflict",
          },
        ];

        return (
          <TabPane tab={upperKey} key={upperKey}>
            <Table
              dataSource={conflictRows}
              columns={columns}
              pagination={true}
              rowKey="field" // Use a unique key for rows, here 'field' is used as a key
            />
            <Title level={4}>
              Accuracy: {isNaN(accuracy) ? 0 : accuracy.toFixed(2)}%
            </Title>
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default CrossCheckTable;
