/* eslint-disable jsx-a11y/anchor-is-valid */
import { RedoOutlined, FileAddOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import AppHeader from "../components/AppHeader/AppHeader";
import getAuth from "../functions/getAuth";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";

const formatTimeDiff = (x) => {
  if (x && x.completed_at && x.created_at) {
    const timeDiffMs = new Date(x.completed_at) - new Date(x.created_at);

    if (timeDiffMs < 0) return "N/A";

    const seconds = Math.floor((timeDiffMs / 1000) % 60);
    const minutes = Math.floor((timeDiffMs / (1000 * 60)) % 60);
    const hours = Math.floor(timeDiffMs / (1000 * 60 * 60));

    const formattedTime =
      hours.toString() +
      " hrs " +
      minutes.toString() +
      " mins " +
      seconds.toString() +
      "s";

    return formattedTime;
  } else {
    return "N/A";
  }
};

const getJobTagColor = (jobStatus) => {
  switch (jobStatus) {
    case "pending":
      return "yellow";
    case "processing":
      return "blue";
    case "completed":
      return "green";
    case "error":
      return "red";
    default:
      return "grey";
  }
};

const HyderabadPharmaHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userFilterOptions, setUserFilterOptions] = useState(["All Users"]);
  const [filteredUser, setFilteredUser] = useState("All Users");
  const nav = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getAuth("/api/hyd_pharma_history")
      .then(async (r) => {
        const res = await r.json();
        setIsLoading(false);
        setHistoryData(res);

        const uniqueUsers = new Set(
          res.map((x) => x.request_creator).filter((x) => x && x !== "txyz")
        );

        const userOptions = ["All Users", ...uniqueUsers];

        setFilteredUser("All Users");
        setUserFilterOptions(userOptions);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, [refreshCounter]);

  return (
    <>
      <Layout>
        <AppHeader />
        <Layout.Content style={{ padding: "0 48px", height: 1000 }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <a href="/" onClick={() => window.location.assign("/")}>
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd">Hyderabad Team</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="/hyd/hyd-pharma">Hyderabad Pharma</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => {}}>
              <a href="#" style={{ color: "black" }}>
                History
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              icon={<FileAddOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                nav("/hyd/hyd-pharma/upload");
              }}
            >
              Upload New
            </Button>
            <Button
              icon={<RedoOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => setRefreshCounter(refreshCounter + 1)}
            >
              Refresh
            </Button>
            <Typography.Text style={{ marginLeft: 10, marginRight: 10 }}>
              Filter By User:{" "}
            </Typography.Text>
            <Select
              style={{ minWidth: 200 }}
              value={filteredUser}
              options={[
                { label: "All Users", value: "All Users" },
                ...userFilterOptions
                  .filter((x) => x !== "All Users")
                  .map((x) => ({ label: x, value: x })),
              ]}
              onChange={(e) => setFilteredUser(e)}
            />
          </div>
          {isLoading ? (
            <Spin style={{ marginTop: 20 }} />
          ) : (
            <Table
              style={{ marginTop: 10 }}
              dataSource={historyData
                .sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .filter((x) => {
                  if (filteredUser === "All Users") {
                    return x;
                  } else {
                    return x.request_creator === filteredUser;
                  }
                })
                .map((x) => ({
                  project_name: x.project_name,
                  request_creator:
                    x?.request_creator?.toString() === "txyz"
                      ? "Talentberry Staff"
                      : x.request_creator || "N/A",
                  created_at: new Date(x.created_at)
                    .toString()
                    .replace("India Standard Time", "IST")
                    .replace("GMT+0530", ""),
                  request_id: x.request_id.toString(),
                  request_status: (
                    <Tag color={getJobTagColor(x.request_status)}>
                      {x?.request_status?.toUpperCase()}
                    </Tag>
                  ),
                  input_file_link: (
                    <Button
                      onClick={() => {
                        getAuth(x.input_file_link)
                          .then(async (r) => {
                            saveAs(
                              await r.blob(),
                              x.input_file_link.split("?file=").pop()
                            );
                          })
                          .catch((e) => alert(e.toString()));
                      }}
                    >
                      Download
                    </Button>
                  ),
                  output_file_link: x.output_file_link ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            getAuth(x.output_file_link)
                              .then(async (r) => {
                                saveAs(
                                  await r.blob(),
                                  x.output_file_link.split("?file=").pop()
                                );
                              })
                              .catch((e) => alert(e.toString()));
                          }}
                        >
                          Download
                        </Button>
                        <Button
                          onClick={() => {
                            nav(`/hyd/hyd-pharma/cross-check/${x.request_id}`);
                          }}
                        >
                          Cross-Check
                        </Button>
                      </ButtonGroup>
                    </div>
                  ) : (
                    <Button disabled={true}>Download</Button>
                  ),
                  duration: formatTimeDiff(x),
                }))}
              columns={[
                { dataIndex: "request_id", title: "Request ID" },
                { dataIndex: "request_creator", title: "Request Creator" },
                {
                  dataIndex: "created_at",
                  title: "Created At",
                  sorter: (a, b) =>
                    new Date(a.created_at) - new Date(b.created_at),
                  defaultSortOrder: "descend",
                },
                {
                  dataIndex: "duration",
                  title: "Duration",
                },
                { dataIndex: "project_name", title: "Project" },
                { dataIndex: "request_status", title: "Status" },
                { dataIndex: "input_file_link", title: "Input File" },
                { dataIndex: "output_file_link", title: "Output Download" },
              ]}
            ></Table>
          )}
        </Layout.Content>
      </Layout>
    </>
  );
};

export default HyderabadPharmaHistory;
